import { reactive } from 'vue';
import { io } from 'socket.io-client';
import { BASE_URL } from './helpers/global';

import { useClickpuDataStore } from './store/clickupDataStore';
import { usePopsDataStore } from './store/popsDataStore';

export const state = reactive({
  connected: false,
  clickupEvents: [] as any[],
  popsEvents: [] as any[],
});

// "undefined" means the URL will be computed from the `window.location` object
const URL = BASE_URL;

export const socket = io(URL);

socket.on('webhookData', (...args) => {
  state.clickupEvents.push(args);
  console.log('webhookData', args);
  const clickupStore = useClickpuDataStore();
  clickupStore.setClickUpData(args);
});

socket.on('popsData', (...args) => {
  state.popsEvents.push(args);
  console.log('popsData', args);
  const popsStore = usePopsDataStore();
  popsStore.setPopsData(args);
  console.log('popsData', popsStore.pops);
});
