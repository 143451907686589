
import { defineComponent } from 'vue';
import CardComponent from './CardComponent.vue';

export default defineComponent({
  name: 'CardsComponents',
  data() {
    const currentEmployees = this.employees.map((emp) => ({
      ...emp,
      show: true,
    }));
    return {
      show: true,
      currentEmployees,
    };
  },
  components: {
    CardComponent,
  },
  methods: {
    setLoadingComplete(personId: string, value: boolean) {
      this.currentEmployees = this.currentEmployees.map((emp) => {
        if (emp._id === personId) {
          return { ...emp, show: value };
        }
        return emp;
      });
    },
  },
  props: {
    reload: {
      type: Boolean,
      required: true,
    },
    employees: {
      type: Array as () => {
        name: string;
        token: string;
        _id: string;
        selected: boolean;
      }[],
      required: true,
    },
  },
  watch: {
    employees: {
      immediate: true,
      handler(newEmployees) {
        this.currentEmployees = newEmployees.map((emp: any) => ({
          ...emp,
          show: true,
        }));
      },
    },
  },
});
