
import { defineComponent } from 'vue';
import CardsComponents from './CardsComponents.vue';
import ListsComponent from './ListsComponent.vue';
import PlansComponent from './PlansComponent.vue';
import TablesComponent from './TablesComponent.vue';
import axios from 'axios';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import { BASE_URL } from '../helpers/global';
import { useListsStore } from '@/store/listsStore';
import { usePopsDataStore } from '@/store/popsDataStore';

export default defineComponent({
  name: 'CardsComponent',
  data() {
    const listsStore = useListsStore();
    const popsStore = usePopsDataStore();
    return {
      people: [] as {
        _id: string;
        name: string;
        token: string;
        selected: boolean;
      }[],
      color: '#6a6ba8',
      size: '50px',
      listsStore,
      popsStore,
      spaces: listsStore.lists,
      popsData: null,
    };
  },
  components: {
    CardsComponents,
    ListsComponent,
    PlansComponent,
    TablesComponent,
    PulseLoader,
  },
  props: {
    reload: {
      type: Boolean,
      required: true,
    },
  },
  created() {
    this.fetchEmployees();
  },
  methods: {
    fetchEmployees() {
      const url = BASE_URL + 'employees';
      axios
        .get(url)
        .then(async (response) => {
          if (response.data.employees === null) {
            console.log('No data');
          } else {
            this.people = response.data.employees;
            await this.fetchData();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async fetchData() {
      const token = this.people[0].token;
      await this.listsStore.fetchLists(token);
      this.spaces = this.listsStore.lists;
    },
  },
  computed: {
    getUrl(): string {
      if (this.$route.name === 'home') {
        return 'home';
      } else if (this.$route.name === 'plans') {
        return 'plans';
      } else {
        return 'tasks';
      }
    },
  },
  watch: {
    'popsStore.pops': {
      handler: function (newPopsData, oldPopsData) {
        console.log('popsData changed');
        if (newPopsData !== oldPopsData) {
          this.fetchEmployees();
        }
      },
      deep: true,
    },
  },
});
