import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import { createPinia } from 'pinia';

import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '@mdi/font/css/materialdesignicons.css';
import MasonryWall from '@yeger/vue-masonry-wall';

import { socket } from '@/socket';

import { useClickpuDataStore } from './store/clickupDataStore';
import { usePopsDataStore } from './store/popsDataStore';

const vuetify = createVuetify({
  components,
  directives,
});

const pinia = createPinia();
const app = createApp(App);

app.use(router).use(pinia).use(vuetify).use(MasonryWall).mount('#app');

socket.on('webhookData', (data) => {
  console.log('Received ClickUp Event:', data);
  const clickupStore = useClickpuDataStore();
  clickupStore.setClickUpData(data);
});

socket.on('popsData', (data) => {
  console.log('Received POPS Event:', data);
  const popsStore = usePopsDataStore();
  popsStore.setPopsData(data);
});
