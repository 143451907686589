import { defineStore } from 'pinia';
import { BASE_URL } from '../helpers/global';
import axios from 'axios';

export const useListsStore = defineStore({
  id: 'listsStore',
  state: () => ({
    lists: [],
  }),
  actions: {
    setLists(data: any) {
      this.lists = data;
    },
    async fetchLists(token: string) {
      const url = BASE_URL + 'spaces';
      const headers = {
        Authorization: token,
      };

      try {
        const response = await axios.get(url, { headers });
        if (response.data.data === null) {
          console.log('No data');
        } else {
          this.setLists(response.data.spaces);
          console.log(this.lists);
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
});
