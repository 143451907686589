
import { defineComponent } from 'vue';
import axios from 'axios';
import { Plan } from '@/types';
import { isObjEmpty } from '@/helpers/isObjEmpty';
import { format } from 'date-fns';
import { InstagramLoader } from 'vue-content-loader';
import { BASE_URL } from '../helpers/global';

export default defineComponent({
  name: 'PlansComponent',
  props: {
    reload: {
      type: Boolean,
      required: true,
    },
    employees: {
      type: Array as () => {
        name: string;
        token: string;
        _id: string;
        selected: boolean;
      }[],
      required: true,
    },
  },
  components: {
    InstagramLoader,
  },
  data() {
    return {
      plans: [] as Plan[],
      loading: true,
      noPlans: false,
    };
  },
  methods: {
    fetchPlans() {
      const token = this.employees[0].token;
      const url = BASE_URL + 'daily-plans';
      const headers = {
        Authorization: token,
      };

      axios
        .get(url, { headers })
        .then((response) => {
          if (response.data.tasks.length === 0) {
            console.log('No data');
            this.loading = false;
            this.noPlans = true;
            return;
          } else {
            this.loading = false;
            this.plans = response.data.tasks.map((plan: Plan) => {
              return {
                id: plan.id,
                name: plan.name,
                creator: plan.creator,
                color: plan.color,
                description: plan.description.split('\n'),
                created_at: format(
                  new Date(+plan.created_at),
                  'dd/MM/yyyy HH:mm'
                ),
                due_date: format(new Date(+plan.due_date), 'dd/MM/yyyy HH:mm'),
                url: plan.url,
                assigned_to: plan.assigned_to,
              };
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    hexToRgb(
      hex: string,
      result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    ) {
      return result ? result.map((i) => parseInt(i, 16)).slice(1) : null;
    },
    getFirstLetterOfName(name: string): string {
      if (name === 'ClickBot') return 'bot';
      const shortcut = name.split(' ');
      if (shortcut.length === 1) return name[0].charAt(0).toUpperCase();
      if (shortcut.length === 2) {
        return (
          shortcut[0].charAt(0).toUpperCase() +
          shortcut[1].charAt(0).toUpperCase()
        );
      }
      if (shortcut.length === 3) {
        return (
          shortcut[0].charAt(0).toUpperCase() +
          shortcut[1].charAt(0).toUpperCase() +
          shortcut[2].charAt(0).toUpperCase()
        );
      }
      return '';
    },
    circleStyle(color: string): object {
      return {
        backgroundColor: color,
      };
    },
  },
  computed: {
    empty(): boolean {
      return isObjEmpty(this.plans);
    },
  },
  created() {
    if (this.employees.length > 0 && this.employees[0].token) {
      this.fetchPlans();
    }
  },
  watch: {
    employees: {
      immediate: true, // Trigger the watcher immediately when the component is created
      handler(newEmployees) {
        if (newEmployees.length > 0 && newEmployees[0].token) {
          this.fetchPlans();
        }
      },
    },
    reload() {
      this.plans = [] as Plan[];
      this.fetchPlans();
    },
  },
});
