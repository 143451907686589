
import { defineComponent } from 'vue';
import TableComponent from '@/components/AllTasksTable.vue';

export default defineComponent({
  name: 'TablesComponent',
  data() {
    return {};
  },
  methods: {
    expand(index: string) {
      const employee = this.employees.find(
        (employee) => employee._id === index
      );
      if (employee) {
        employee.selected = !employee.selected;
      }
      const element = document.getElementById(employee!._id) as HTMLDivElement;
      if (element) {
        element.classList.toggle('d-none');
        if (element.previousElementSibling) {
          element.previousElementSibling.classList.toggle(
            'text-light-blue-darken-4'
          );
          element.previousElementSibling.classList.toggle('scale');
          element.previousElementSibling.classList.toggle('font-weight-bold');
        }
      }
      // when I click on the employee other employees should collapse
      const employees = this.employees.filter(
        (employee) => employee._id !== index
      );
      employees.forEach((employee) => {
        employee.selected = false;
        const element = document.getElementById(employee._id) as HTMLElement;
        if (element) {
          element.classList.add('d-none');
          if (element.previousElementSibling) {
            element.previousElementSibling.classList.remove(
              'text-light-blue-darken-4'
            );
            element.previousElementSibling.classList.remove('scale');
            element.previousElementSibling.classList.remove('font-weight-bold');
          }
        }
      });
    },
  },
  props: {
    employees: {
      type: Array as () => {
        name: string;
        token: string;
        _id: string;
        selected: boolean;
      }[],
      required: true,
    },
    reload: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    TableComponent,
  },
  watch: {
    reload() {
      console.log('reload');
    },
  },
});
