import { defineStore } from 'pinia';

export const usePopsDataStore = defineStore({
  id: 'popsStore',
  state: () => ({
    pops: {},
  }),
  actions: {
    setPopsData(data: any) {
      this.pops = data;
    },
  },
});
