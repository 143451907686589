import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b0c469de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-row justify-start align-center" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 0,
  class: "text-lg-h5 text-md-h6 hide"
}
const _hoisted_4 = {
  key: 1,
  class: "text-lg-h5 text-md-h6 hide"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_responsive = _resolveComponent("v-responsive")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_MainView = _resolveComponent("MainView")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_layout = _resolveComponent("v-layout")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_layout, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_app_bar, {
            class: "bg-blue-grey-lighten-4",
            prominent: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_toolbar_title, { style: {"color":"#525050"} }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("img", {
                      src: require('@/assets/sowlutions-logo.png'),
                      alt: "ClickUp",
                      class: "image"
                    }, null, 8, _hoisted_2),
                    _createVNode(_component_v_responsive, {
                      md: false,
                      sm: true
                    }, {
                      default: _withCtx(() => [
                        (_ctx.$route.name === 'home')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_3, " ClickUp Tasks for Sowlutions Team "))
                          : (_openBlock(), _createElementBlock("span", _hoisted_4, " ClickUp Daily Plans "))
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_v_spacer),
              _createVNode(_component_router_link, {
                class: _normalizeClass(["nav-link link text-lg-h5", _ctx.getUrl === 'home' ? 'text-blue-accent-3' : null]),
                to: { name: 'home' }
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Home")
                ]),
                _: 1
              }, 8, ["class"]),
              _createVNode(_component_router_link, {
                class: _normalizeClass(["nav-link ml-4 link text-lg-h5", _ctx.getUrl === 'plans' ? 'text-blue-accent-3' : null]),
                to: { name: 'plans' }
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Plans")
                ]),
                _: 1
              }, 8, ["class"]),
              _createVNode(_component_router_link, {
                class: _normalizeClass(["nav-link ml-4 link text-lg-h5", _ctx.getUrl === 'tasks' ? 'text-blue-accent-3' : null]),
                to: { name: 'tasks' }
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Tasks")
                ]),
                _: 1
              }, 8, ["class"]),
              _createVNode(_component_v_btn, {
                icon: "",
                class: "ml-3"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, {
                    onClick: _ctx.reload,
                    style: {"color":"#525050"},
                    size: "28"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("mdi-reload")
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_main, {
            style: {"min-height":"100vh"},
            class: _normalizeClass(_ctx.getUrl === 'plans' ? 'bg-blue-lighten-4' : null)
          }, {
            default: _withCtx(() => [
              _createVNode(_component_MainView, { reload: _ctx.reloadPage }, null, 8, ["reload"])
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}