
import { defineComponent } from 'vue';
import MainView from './MainView.vue';

export default defineComponent({
  components: {
    MainView,
  },
  data() {
    return {
      reloadPage: false,
    };
  },
  methods: {
    reload(): void {
      this.reloadPage = !this.reloadPage;
    },
  },
  computed: {
    getUrl(): string {
      if (this.$route.name === 'home') {
        return 'home';
      } else if (this.$route.name === 'plans') {
        return 'plans';
      } else {
        return 'tasks';
      }
    },
  },
});
