
import { defineComponent } from 'vue';
import { format } from 'date-fns';
import axios from 'axios';
import { BASE_URL } from '../helpers/global';
import startTimer from '../helpers/timer';
import { useListsStore } from '@/store/listsStore';
import { usePopsDataStore } from '@/store/popsDataStore';

export default defineComponent({
  name: 'ListsComponent',
  data() {
    const listsStore = useListsStore();
    const popsDataStore = usePopsDataStore();
    return {
      listsStore,
      popsDataStore,
      tasks: [] as any[],
      reportTasks: [] as any[],
      startTime: '',
      totalWorkTime: '',
      totalBreakTime: '',
      formattedWorkTime: '',
      formattedBreakTime: '',
      dialog: false,
      spaces: [],
      workTimerInterval: null as number | null,
      breakTimerInterval: null as number | null,
      isLoggedIn: null as boolean | null,
    };
  },
  methods: {
    fetchData() {
      const token = this.employee.token;
      const url = BASE_URL + 'all-tasks';
      const currentTaskUrl = BASE_URL + 'current-tasks'; // URL to fetch current working task
      const headers = {
        headers: {
          Authorization: token,
        },
      };

      // Fetch both all tasks and current working task simultaneously
      axios
        .all([axios.get(url, headers), axios.get(currentTaskUrl, headers)])
        .then(
          axios.spread((allTasksResponse, currentTaskResponse) => {
            const tasksBySpace = this.buildTasksBySpace(
              allTasksResponse.data.tasks.data
            );
            const currentTaskData = currentTaskResponse.data.currentTasks.data;
            this.calculateTotalDurations(tasksBySpace, currentTaskData);
            this.calculateBreakTime(
              allTasksResponse.data.tasks.data,
              currentTaskData
            );
            this.tasks = Object.values(tasksBySpace);
            this.combineTasksWithSameName();
            this.isLoggedIn = this.employee.loggedIn;
          })
        )
        .catch((error) => {
          console.log(error);
        });
    },
    buildTasksBySpace(data: any[]): { [key: string]: any } {
      const tasksBySpace: { [key: string]: any } = {};

      const getSpaceNameById = (spaceId: string): string | undefined => {
        return (
          this.spaces.find((space: any) => space.id === spaceId) as unknown as {
            name: string;
          }
        )?.name;
      };

      const getListNameById = (
        spaceId: string,
        listId: string
      ): string | undefined => {
        const space = this.spaces.find(
          (space: any) => space.id === spaceId
        ) as unknown as { lists: any[] };
        return space?.lists.find((list: any) => list.id === listId)?.name;
      };

      data.forEach((record: any) => {
        if (!record.task) return;
        const spaceId = record?.task_location.space_id;
        const listId = record?.task_location.list_id;

        if (!tasksBySpace[spaceId]) {
          tasksBySpace[spaceId] = {
            space: getSpaceNameById(spaceId),
            totalSpaceDuration: 0,
            lists: {},
          };
        }

        const space = tasksBySpace[spaceId];

        if (!space.lists[listId]) {
          space.lists[listId] = {
            list: getListNameById(spaceId, listId),
            totalListDuration: 0,
            tasks: [],
          };
        }

        const list = space.lists[listId];

        const existingTaskGroup = list.tasks.find(
          (taskGroup: any) => taskGroup.title === record?.task.name
        );

        if (existingTaskGroup) {
          existingTaskGroup.tasks.push(this.buildTask(record));
          list.totalListDuration += Math.abs(record.duration);
        } else {
          list.tasks.push({
            title: record?.task.name,
            tasks: [this.buildTask(record)],
          });
          list.totalListDuration += Math.abs(record.duration);
        }

        space.totalSpaceDuration += Math.abs(record.duration);
      });

      return tasksBySpace;
    },
    buildTask(record: any) {
      const durationInSeconds = Math.abs(record.duration) / 1000;
      return {
        id: record.id,
        title: record?.task?.name || 'No name',
        status: record?.task.status.status || 'No status',
        color: record?.task.status.color || '#fff',
        duration: record.duration,
        description: record.description,
        formattedDuration: new Date(durationInSeconds * 1000)
          .toISOString()
          .substr(11, 8),
        link: record?.task_url || '',
        startTime: format(new Date(+record.start), 'HH:mm:ss'),
        endTime: format(new Date(+record.end), 'HH:mm:ss'),
      };
    },
    calculateTotalDurations(tasksBySpace: any, currentTaskData: any) {
      let totalDuration = 0;
      Object.values(tasksBySpace).forEach((space: any) => {
        Object.values(space.lists).forEach((list: any) => {
          totalDuration += list.totalListDuration;
        });
      });

      // Add the duration of the current working task
      if (currentTaskData) {
        const currentTaskDuration = Math.abs(currentTaskData.duration);
        totalDuration += currentTaskDuration;
      }

      this.totalWorkTime = this.formatDuration(totalDuration);
      if (currentTaskData) {
        this.timer(this.totalWorkTime, 'work');
      } else {
        this.formattedWorkTime = this.totalWorkTime;
      }
    },
    calculateBreakTime(data: any[], currentTaskData: any) {
      // Sort tasks in ascending order based on start time
      if (data.length === 0) {
        this.formattedBreakTime = '--:--:--';
      }
      data.sort((a, b) => parseInt(a.start) - parseInt(b.start));
      let totalBreakTime = 0;
      for (let i = 0; i < data.length - 1; i++) {
        const currentTaskEndTime = parseInt(data[i].end);
        const nextTaskStartTime = parseInt(data[i + 1].start);
        // Calculate the time difference in milliseconds
        const breakDuration = nextTaskStartTime - currentTaskEndTime;
        // Only consider positive break durations
        if (breakDuration > 0) {
          totalBreakTime += breakDuration;
        }
      }

      // If there's a current task, calculate the break between its start time and the last task's end time
      if (currentTaskData) {
        const currentTaskStartTime = parseInt(currentTaskData.start);
        const lastTaskIndex = data.length - 1;
        const lastTaskEndTime = parseInt(data[lastTaskIndex].end);
        // Calculate the time difference in milliseconds
        const breakAfterLastTask = currentTaskStartTime - lastTaskEndTime;
        // Only consider positive break durations
        if (breakAfterLastTask > 0) {
          totalBreakTime += breakAfterLastTask;
        }
      }

      // Convert total break time to formatted duration
      this.totalBreakTime = this.formatDuration(totalBreakTime);
      if (!currentTaskData && this.isLoggedIn) {
        this.timer(this.totalBreakTime, 'break');
      } else {
        this.formattedBreakTime = this.totalBreakTime;
      }
    },

    getTotalDuration(data: any[]) {
      let totalDuration = 0;
      data.forEach((record: any) => {
        totalDuration += Math.abs(record.duration);
      });
      return totalDuration;
    },
    taskStyle(color: string): string {
      return `background-color: ${color};`;
    },
    formatDuration(duration: number) {
      const hours = Math.floor(duration / 3600000);
      const minutes = Math.floor((duration % 3600000) / 60000);
      const seconds = Math.floor((duration % 60000) / 1000);

      const formattedDuration = `${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

      return formattedDuration;
    },
    timer(time: string, type: string) {
      if (this.workTimerInterval) {
        clearInterval(this.workTimerInterval);
      }
      if (this.breakTimerInterval) {
        clearInterval(this.breakTimerInterval);
      }
      if (type === 'work') {
        this.workTimerInterval = startTimer(time, (formattedTime) => {
          this.formattedWorkTime = '';
          this.formattedWorkTime = formattedTime;
        });
      } else if (type === 'break') {
        this.breakTimerInterval = startTimer(time, (formattedTime) => {
          this.formattedBreakTime = '';
          this.formattedBreakTime = formattedTime;
        });
      }
    },
    combineTasksWithSameName() {
      const tasksCopy = JSON.parse(JSON.stringify(this.tasks));
      this.reportTasks = tasksCopy.map(
        (item: { lists: { [x: string]: any } }) => {
          const combinedLists: Record<string, any> = {};

          for (const listId in item.lists) {
            const list = item.lists[listId];
            const combinedTasks: Record<
              string,
              { title: string; duration: number }
            > = {};

            for (const taskGroup of list.tasks) {
              for (const task of taskGroup.tasks) {
                const { title, duration } = task;

                if (combinedTasks[title]) {
                  combinedTasks[title].duration += parseInt(duration);
                } else {
                  combinedTasks[title] = {
                    title,
                    duration: parseInt(duration),
                  };
                }
              }
            }

            const combinedTaskArray = Object.values(combinedTasks);
            list.totalListDuration = combinedTaskArray.reduce(
              (totalDuration, task) => totalDuration + task.duration,
              0
            );
            list.tasks = [{ tasks: combinedTaskArray }];
            combinedLists[listId] = list;
          }

          return {
            ...item,
            lists: combinedLists,
          };
        }
      );
    },
  },
  computed: {
    popsData(): any {
      return this.popsDataStore.pops;
    },
  },
  props: {
    employee: {
      type: Object,
      required: true,
    },
    reload: {
      type: Boolean,
      required: true,
    },
  },
  created() {
    this.fetchData();
    this.spaces = this.listsStore.lists;
    console.log(this.employee);
  },
  watch: {
    reload() {
      this.tasks = [];
      this.fetchData();
    },
  },
});
