import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import PlansView from '../views/PlansView.vue';
import TasksView from '../views/TasksView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/plans',
    name: 'plans',
    component: PlansView,
  },
  {
    path: '/tasks',
    name: 'tasks',
    component: TasksView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
