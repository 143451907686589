import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5e7749dd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "employee-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_EmployeeAllTasks = _resolveComponent("EmployeeAllTasks")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_list, {
      class: "bg-shades-white rounded",
      style: {"width":"90vw"}
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.employees, (employee) => {
          return (_openBlock(), _createBlock(_component_v_list_item, {
            key: employee._id
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_list_item_title, {
                class: "d-flex justify-space-between employee",
                onClick: ($event: any) => (_ctx.expand(employee._id))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_2, _toDisplayString(employee.name), 1),
                  (employee.selected)
                    ? (_openBlock(), _createBlock(_component_v_icon, { key: 0 }, {
                        default: _withCtx(() => [
                          _createTextVNode("mdi-chevron-up")
                        ]),
                        _: 1
                      }))
                    : (!employee.selected)
                      ? (_openBlock(), _createBlock(_component_v_icon, { key: 1 }, {
                          default: _withCtx(() => [
                            _createTextVNode("mdi-chevron-down")
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1032, ["onClick"]),
              _createVNode(_component_EmployeeAllTasks, {
                employee: employee,
                id: employee._id,
                class: "d-none",
                reload: _ctx.reload
              }, null, 8, ["employee", "id", "reload"])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    })
  ]))
}