import { defineStore } from 'pinia';

export const useClickpuDataStore = defineStore({
  id: 'dataStore',
  state: () => ({
    clickUp: null,
  }),
  actions: {
    setClickUpData(data: any) {
      this.clickUp = data;
    },
  },
});
