
import { defineComponent } from 'vue';
import { format } from 'date-fns';
import axios from 'axios';
import { BASE_URL } from '../helpers/global';
import startTimer from '../helpers/timer';
import { useListsStore } from '@/store/listsStore';

export default defineComponent({
  name: 'tableComponent',
  data() {
    const listsStore = useListsStore();
    return {
      listsStore,
      tasks: [] as any[],
      reportTasks: [] as any[],
      startTime: '',
      dialog: false,
      spaces: [] as {
        id: number;
        name: string;
        lists: {
          id: number;
          name: string;
          folder: {
            id: number;
            name: string;
            hidden: boolean;
            access: boolean;
          };
          color: string;
        }[];
      }[],
      workTimerInterval: null as number | null,
      breakTimerInterval: null as number | null,
      formattedTotalWorkTime: '',
      formattedTotalBreakTime: '',
      space: '',
      list: ',',
    };
  },
  methods: {
    async fetchData() {
      const token = this.employee.token;
      const url = BASE_URL + 'all-tasks';
      const currentTaskUrl = BASE_URL + 'current-tasks';
      const headers = {
        headers: {
          Authorization: token,
        },
      };

      try {
        const responses = await axios.all([
          axios.get(url, headers),
          axios.get(currentTaskUrl, headers),
        ]);
        const allTasksResponse = responses[0];
        this.tasks = allTasksResponse.data.tasks.data;

        for (let task of this.tasks) {
          task.start = format(new Date(parseInt(task.start)), 'HH:mm:ss');
          task.end = format(new Date(parseInt(task.end)), 'HH:mm:ss');
          let foundSpace = this.spaces.find(
            (space: any) => space.id === task.task_location.space_id
          );

          if (foundSpace) {
            task.space = foundSpace.name;
            let foundList = foundSpace.lists.find(
              (list: any) => list.id === task.task_location.list_id
            );
            task.list = foundList ? foundList.name : '';
          } else {
            this.space = '';
            this.list = '';
          }
        }
        await this.addBreaks();
      } catch (error) {
        console.log(error);
      }
    },

    async addBreaks() {
      this.tasks.sort(
        (a, b) =>
          Date.parse('1970-01-01T' + a.start + 'Z') -
          Date.parse('1970-01-01T' + b.start + 'Z')
      );

      for (let i = 0; i < this.tasks.length - 1; i++) {
        if (
          Date.parse('1970-01-01T' + this.tasks[i].end + 'Z') <
          Date.parse('1970-01-01T' + this.tasks[i + 1].start + 'Z')
        ) {
          const startTime = new Date('1970-01-01T' + this.tasks[i].end + 'Z');
          const endTime = new Date(
            '1970-01-01T' + this.tasks[i + 1].start + 'Z'
          );
          const duration = endTime.getTime() - startTime.getTime();
          const breakTask = {
            isBreak: true,
            name: 'break',
            start: this.tasks[i].end,
            end: this.tasks[i + 1].start,
            duration: duration,
          };
          this.tasks.splice(i + 1, 0, breakTask);
          i++;
        }
      }
      this.tasks = this.tasks.flat();
    },
    formatDuration(duration: number) {
      const hours = Math.floor(duration / 3600000);
      const minutes = Math.floor((duration % 3600000) / 60000);
      const seconds = Math.floor((duration % 60000) / 1000);

      const formattedDuration = `${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

      return formattedDuration;
    },
  },
  computed: {
    totalWorkTime(): number {
      // Filter out break tasks and sum up their durations
      return this.tasks
        .filter((task) => !task.isBreak)
        .reduce((acc, task) => acc + Number(task.duration), 0);
    },

    totalBreakTime(): number {
      // Filter only break tasks and sum up their durations
      return this.tasks
        .filter((task) => task.isBreak)
        .reduce((acc, task) => acc + Number(task.duration), 0);
    },

    formattedWorkTime(): string {
      return this.formatDuration(this.totalWorkTime);
    },

    formattedBreakTime(): string {
      return this.formatDuration(this.totalBreakTime);
    },
  },
  props: {
    employee: {
      type: Object,
      required: true,
    },
    reload: {
      type: Boolean,
      required: true,
    },
  },
  created() {
    this.fetchData();
    this.spaces = this.listsStore.lists;
  },
  watch: {
    reload() {
      this.tasks = [];
      this.fetchData();
    },
  },
});
