
import { defineComponent } from 'vue';
import axios from 'axios';
import { DataClass } from '@/types';
import { format } from 'date-fns';
import { isObjEmpty } from '@/helpers/isObjEmpty';
import { FacebookLoader } from 'vue-content-loader';
import { BASE_URL } from '../helpers/global';
import startTimer from '../helpers/timer';
import { useClickpuDataStore } from '@/store/clickupDataStore';
import { usePopsDataStore } from '@/store/popsDataStore';
import { useListsStore } from '@/store/listsStore';

export default defineComponent({
  name: 'CardComponent',
  components: {
    FacebookLoader,
  },
  props: {
    person: {
      type: Object,
      required: true,
    },
    reload: {
      type: Boolean,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['loadingComplete'],
  data() {
    const clickupDataStore = useClickpuDataStore();
    const popsDataStore = usePopsDataStore();
    const listsStore = useListsStore();
    return {
      data: {} as DataClass,
      date: '',
      clickupDataStore,
      popsDataStore,
      listsStore,
      duration: '',
      formattedTaskDuration: '',
      color: 'lightGray',
      borderColor: 'yellow',
      list: '',
      space: '',
      spaces: [] as {
        id: number;
        name: string;
        lists: {
          id: number;
          name: string;
          folder: {
            id: number;
            name: string;
            hidden: boolean;
            access: boolean;
          };
          color: string;
        }[];
      }[],
      loading: false,
      timerInterval: null as number | null,
      taskTimerInterval: null as number | null,
      isLoggedIn: null as boolean | null,
    };
  },
  methods: {
    fetchData() {
      this.loading = true;
      const token = this.person.token;
      const url = BASE_URL + 'current-tasks';
      const headers = {
        Authorization: token,
      };

      axios
        .get(url, { headers })
        .then((response) => {
          if (response.data.currentTasks.data) {
            this.$emit('loadingComplete', this.person._id, true);
            this.data = response.data.currentTasks.data;
            this.date = format(new Date(+this.data.start), 'HH:mm');
            const durationInSeconds = Math.abs(this.data.duration) / 1000;
            this.duration = new Date(durationInSeconds * 1000)
              .toISOString()
              .substr(11, 8);
            this.timer(this.duration);
            let foundSpace = this.spaces.find(
              (space: any) => space.id === this.data.task_location.space_id
            );
            if (foundSpace) {
              this.space = foundSpace.name;
              let foundList = foundSpace.lists.find(
                (list: any) => list.id === this.data.task_location.list_id
              );
              this.list = foundList ? foundList.name : '';
            } else {
              this.space = '';
              this.list = '';
            }
            this.isLoggedIn = this.person.loggedIn;
            this.loading = false;
          } else {
            this.loading = false;
            this.$emit('loadingComplete', this.person._id, false);
            this.data = {} as DataClass;
            return;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    hexToRgb(
      hex: string,
      result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    ) {
      return result ? result.map((i) => parseInt(i, 16)).slice(1) : null;
    },
    timer(time: string) {
      // Clear the previous timer interval if it exists
      if (this.timerInterval) {
        clearInterval(this.timerInterval);
      }

      // Start a new timer interval and store the reference
      this.timerInterval = startTimer(time, (formattedTime) => {
        this.formattedTaskDuration = '';
        this.formattedTaskDuration = formattedTime;
      });
    },
    startPolling() {
      if (this.isLoggedIn && this.emptyTasks) {
        this.fetchData();
        this.taskTimerInterval = setInterval(this.fetchData, 30000);
      } else {
        this.stopPolling();
      }
    },
    stopPolling() {
      if (this.taskTimerInterval) {
        clearInterval(this.taskTimerInterval);
        this.taskTimerInterval = null;
      }
      if (this.isLoggedIn) {
        this.isLoggedIn = false;
      }
    },
    async login() {
      setTimeout(() => {
        this.fetchData();
      }, 5000);
    },
    async logout() {
      setTimeout(() => {
        this.fetchData();
      }, 5000);
    },
  },
  computed: {
    empty(): boolean {
      return isObjEmpty(this.data);
    },
    emptyTasks(): boolean {
      if (!this.data?.start) {
        return true;
      } else if (
        !this.data?.task?.name ||
        this.data?.task?.name.trim() === ''
      ) {
        return false;
      } else {
        return isObjEmpty(this.data?.task);
      }
    },
    isUnnamedTask(): boolean {
      return (
        this.data?.task_location &&
        (!this.data.description || this.data.description.trim() === '')
      );
    },
    getFirstLetterOfName(): string {
      const name = this.person?.name.split(' ');
      if (name.length === 1) return name[0].charAt(0);
      if (name.length === 2) {
        return name[0].charAt(0) + ' ' + name[1].charAt(0);
      }
      if (name.length === 3) {
        return (
          name[0].charAt(0) + ' ' + name[1].charAt(0) + ' ' + name[2].charAt(0)
        );
      }
      return '';
    },
    containerStyle(): object {
      return {
        backgroundColor: `rgba(${this.hexToRgb(
          this.data?.task?.status?.color
        )?.join(', ')}, 0.3)`,
        border: `1px solid ${this.data?.task?.status?.color}`,
        color: '#000',
      };
    },
    circleStyle(): object {
      return {
        backgroundColor: this.data?.user?.color,
      };
    },
    clickupData(): any {
      return this.clickupDataStore.clickUp;
    },
    popsData(): any {
      return this.popsDataStore.pops;
    },
  },
  watch: {
    isLoggedIn: 'startPolling',
    emptyTasks: 'startPolling',
    reload() {
      this.data = {} as DataClass;
      this.formattedTaskDuration = '';
      this.duration = '';
      this.fetchData();
    },
    clickupData(newVal, oldVal) {
      if (!newVal.history_items) {
        console.log('No history', newVal);
      } else if (newVal?.history_items[0].user.username === this.person.name) {
        this.fetchData();
      }
    },
    popsData(newVal, oldVal) {
      if (!newVal) {
        console.log('No history', newVal);
      } else if (
        newVal?.data.employee.full_name.toLowerCase() ===
          this.person.name.toLowerCase() &&
        newVal.data.log_type === 'checkin'
      ) {
        this.login();
      } else if (
        newVal?.data.employee.full_name.toLowerCase() ===
          this.person.name.toLowerCase() &&
        newVal.data.log_type === 'checkout'
      ) {
        this.logout();
      }
    },
    watchEmptyTasks(newVal, oldVal) {
      console.log('emptyTasks changed:', newVal);
    },
  },
  mounted() {
    this.startPolling();
  },
  created() {
    this.fetchData();
    this.spaces = this.listsStore.lists;
  },
  beforeUnmount() {
    this.stopPolling();
  },
});
