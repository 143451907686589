import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-29f23239"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "current-tasks-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardComponent = _resolveComponent("CardComponent")!

  return (_ctx.currentEmployees.length > 0)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentEmployees, (person, index) => {
          return (_openBlock(), _createElementBlock("div", { key: index }, [
            _createVNode(_component_CardComponent, {
              person: person,
              reload: _ctx.reload,
              show: person.show,
              onLoadingComplete: _ctx.setLoadingComplete
            }, null, 8, ["person", "reload", "show", "onLoadingComplete"])
          ]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}