export default function startTimer(
  initialTime: string,
  callback: (formattedTime: string) => void,
  stop?: boolean
) {
  const timeParts = initialTime.split(':');
  if (timeParts.length !== 3) {
    console.error('Invalid time format. Please use "HH:mm:ss" format.');
    return null; // Return null if the timer couldn't be started
  }

  let [hours, minutes, seconds] = timeParts.map(Number);

  const timerInterval = setInterval(() => {
    seconds++;

    if (seconds >= 60) {
      seconds = 0;
      minutes++;

      if (minutes >= 60) {
        minutes = 0;
        hours++;
      }
    }

    const formattedTime = `${String(hours).padStart(2, '0')}:${String(
      minutes
    ).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

    callback(formattedTime); // Call the callback function with the formatted time

    // Stop the timer when needed,
    if (stop) {
      clearInterval(timerInterval);
    }
  }, 1000);

  return timerInterval; // Return the timerInterval reference
}
